.main {
    width: 100%;
    height: 95%;
    
    position:absolute;
    background-color: rgb(175, 175, 175);
}

.container {
    display:flex;
    justify-content: center;
    bottom: 0;
}

.box-container {
    width: fit-content;
    padding: 20px;
    border-radius: 1rem;
    box-shadow: rgb(0, 0, 0, 0);
    align-items: center;
}


.wired-button {
    background: white;
    color: black;
    margin: 8px;
}